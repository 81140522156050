import * as React from 'react'
import { useEffect } from 'react'
import CategoryListItem from '../category-list-item'

import { INode } from '../../types/mdx'

import * as styles from './index.module.scss'

interface IGroupItem {
  fieldValue: string
  totalCount: number
  edges: INode[]
}

interface IProps {
  groupItems: IGroupItem[]
  maxTotalCount: number
  selectedCategory: string | undefined
  onSelectedCategory: (category: string | undefined) => void
}

function CategoryList({
  groupItems,
  maxTotalCount,
  selectedCategory,
  onSelectedCategory,
}: IProps) {
  useEffect(() => {
    onSelectedCategory(selectedCategory)
  }, [])

  return (
    <div className={styles.categoryListWrap}>
      <ul role='tablist'>
        {groupItems.map(({ fieldValue: categoryName, totalCount }) => (
          <CategoryListItem
            key={categoryName}
            isSelected={categoryName === selectedCategory}
            category={categoryName}
            totalCount={totalCount}
            maxTotalCount={maxTotalCount}
            onSelectedCategory={onSelectedCategory}
          />
        ))}
      </ul>
    </div>
  )
}

export default CategoryList
