// extracted by mini-css-extract-plugin
export var post = "index-module--post--Mrb3V";
export var hide = "index-module--hide--8Cmjq";
export var title = "index-module--title--qCtzr";
export var info = "index-module--info---tCew";
export var dateWrap = "index-module--date-wrap--IXAo3";
export var date = "index-module--date--vEuvj";
export var update = "index-module--update--ILIp9";
export var infoDot = "index-module--info-dot--Fboh3";
export var tagList = "index-module--tag-list--yKDy-";
export var tag = "index-module--tag--uoK5t";
export var excerpt = "index-module--excerpt--98f68";