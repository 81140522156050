import * as React from 'react'
import { Link } from 'gatsby'

import * as styles from './index.module.scss'

// const CategoryListItem: React.FC<Props> = ({
//   isSelected,
//   category,
//   totalCount,
//   maxTotalCount,
//   onSelectedCategory,
// }) => {
function CategoryListItem({
  isSelected,
  category,
  totalCount,
  maxTotalCount,
  onSelectedCategory,
}: Props) {
  const getFontSize = () => {
    let fontSize = Math.round(50 / (maxTotalCount / totalCount)).toString()
    if (fontSize.length <= 1) fontSize = `0${fontSize}`
    return `${Number(fontSize) / 100 + 0.9}rem`
  }

  return (
    <li key={category} role='tab'>
      <span
        className={`${styles.category} ${isSelected ? styles.selected : ''}`}
        style={{ fontSize: getFontSize() }}
        onClick={() => onSelectedCategory(category)}
        onKeyDown={() => void 0}
        role='button'
        tabIndex={0}
      >
        <Link to={`#${category}`}>{category}</Link>
      </span>
    </li>
  )
}

interface Props {
  isSelected: boolean
  category: string
  totalCount: number
  maxTotalCount: number
  onSelectedCategory: (category: string | undefined) => void
}

export default CategoryListItem
