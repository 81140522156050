import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layout'
import SEO from '../../components/seo'
import CategoryList from '../../components/category-list'
import PostList from '../../components/post-list'
import { useCategories } from '../../hooks/use-categories'

import { INode } from '../../types/mdx'

import * as styles from './index.module.scss'

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            excerpt(truncate: true)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMM DD, YYYY")
              update(formatString: "MMM DD, YYYY")
              title
              tags
              category
            }
          }
        }
      }
    }
  }
`

interface IProps {
  data: {
    allMdx: {
      group: IGroupItem[]
    }
  }
}

interface IGroupItem {
  fieldValue: string
  totalCount: number
  edges: INode[]
}

function sorted(group: IGroupItem[]): IGroupItem[] {
  group.sort((a: IGroupItem, b: IGroupItem) => {
    const x = a.fieldValue.toLocaleLowerCase()
    const y = b.fieldValue.toLocaleLowerCase()

    if (x < y) return -1
    if (y < x) return 1
    return 0
  })

  return group
}

function CategoriesPage({ data }: IProps) {
  const group = sorted(data.allMdx.group)

  const [currentPostList, setCurrentPostList] = useState<INode[]>([])
  const { selectedCategory, setSelectedCategory, maxTotalCount } =
    useCategories(group)

  const getPostList = useCallback(() => {
    if (
      group.filter((g: IGroupItem) => g.fieldValue === selectedCategory).length
    ) {
      return group.filter(
        (g: IGroupItem) => g.fieldValue === selectedCategory
      )[0].edges
    }

    return []
  }, [selectedCategory])

  useEffect(() => {
    if (typeof selectedCategory === `undefined`) return
    const postList = getPostList()
    postList.length > 0 && setCurrentPostList(postList)
  }, [selectedCategory])

  return (
    <Layout>
      <SEO title={`CATEGORIES`} />

      <div id={styles.categories}>
        <h1 className={styles.title}>Categories</h1>

        <CategoryList
          groupItems={group}
          maxTotalCount={maxTotalCount}
          selectedCategory={selectedCategory}
          onSelectedCategory={setSelectedCategory}
        />

        <PostList classNames={styles.postList} posts={currentPostList} />
      </div>
    </Layout>
  )
}

export default CategoriesPage
