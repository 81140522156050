import * as React from 'react'
import { Link } from 'gatsby'

import { INode } from '../../types/mdx'

import * as styles from './index.module.scss'

interface IProps {
  post: INode
}

function PostListItem({ post }: IProps) {
  const { node } = post
  const { excerpt, fields, frontmatter } = node
  const { slug } = fields
  const { date, title, tags } = frontmatter

  let update = frontmatter.update
  if (Number(update?.split(',')[1]) === 1) update = undefined

  const mapTag = tags.map((tag: string) => {
    if (tag === 'undefined') return

    const key = slug + tag

    return (
      <div key={key} className={styles.tag}>
        <span>
          <Link to={`/tags#${tag}`}>{`#${tag}`}</Link>
        </span>
      </div>
    )
  })

  function getUpdatedSpan() {
    if (!update) return

    const updateString: string = update

    return (
      <span className={styles.update}>
        &nbsp;{`(Updated: ${updateString})`}
      </span>
    )
  }

  return (
    <li key={slug} className={styles.post}>
      <article>
        <h2 className={styles.title}>
          <Link to={slug}>{title}</Link>
        </h2>
        <div className={styles.info}>
          <div className={styles.dateWrap}>
            <span className={styles.date}>{date}</span>
            {getUpdatedSpan()}
          </div>
          {tags.length && tags[0] !== 'undefined' ? (
            <span className={styles.infoDot}>·</span>
          ) : null}
          <ul className={styles.tagList}>{mapTag}</ul>
        </div>
        <Link to={slug}>
          <span className={styles.excerpt}>{excerpt}</span>
        </Link>
      </article>
    </li>
  )
}

export default PostListItem
