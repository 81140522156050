import { useState, useEffect } from 'react'

import { INode } from '../types/mdx'

import * as Dom from '../utils/dom'

interface CategoryGroup {
  fieldValue: string
  totalCount: number
  edges: INode[]
}

export const useCategories = (
  categoryGroups: CategoryGroup[]
): {
  selectedCategory: string | undefined
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | undefined>>
  maxTotalCount: number
} => {
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
  const [maxTotalCount, setMaxTotalCount] = useState(0)

  // 최초 접속인 경우, targetCategory 가 'undefined' 이다.
  if (typeof selectedCategory === `undefined`) {
    const result = categoryGroups
      .map((e) => {
        return e.edges.sort((a, b) => {
          const af = a.node.frontmatter
          const bf = b.node.frontmatter

          const aDate = new Date(
            af.update?.includes('0001') ? af.date : af.update || af.date
          )
          const bDate = new Date(
            bf.update?.includes('0001') ? bf.date : bf.update || bf.date
          )

          if (aDate < bDate) return 1
          if (aDate > bDate) return -1
          return 0
        })[0].node.frontmatter
      })
      .sort((a, b) => {
        const aDate = new Date(a.date)
        const bDate = new Date(b.date)

        if (aDate < bDate) return 1
        if (aDate > bDate) return -1
        return 0
      })[0]

    const firstTargetCategory = result.category

    if (firstTargetCategory !== selectedCategory) {
      setSelectedCategory(firstTargetCategory)
    }
  }

  useEffect(() => {
    const location = Dom.getLocation()
    setSelectedCategory(
      location?.hash ? location.hash.split('#')[1] : undefined
    )

    let maxTotalCount = 0
    for (const g of categoryGroups) {
      g.totalCount > maxTotalCount && (maxTotalCount = g.totalCount)
    }
    setMaxTotalCount(maxTotalCount)
  }, [])

  return {
    selectedCategory,
    setSelectedCategory,
    maxTotalCount,
  }
}
