import * as React from 'react'
import { INode } from '../../types/mdx'
import PostListItem from '../post-list-item'
import * as styles from './index.module.scss'

interface IProps {
  posts: INode[]
  classNames?: string
}

function PostList({ posts, classNames = '' }: IProps) {
  return (
    <div className={`${styles.postList} ${classNames}`}>
      <ul>
        {posts.map((post) => (
          <PostListItem post={post} key={post.node.fields.slug} />
        ))}
      </ul>
    </div>
  )
}

export default PostList
